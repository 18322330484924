import React from 'react';
import styled from 'styled-components';
import computer from "../assets/computerMonitor2.png"

const BlogContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 100px;
  line-height: 1.6;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5em;
  margin-bottom: 0.5em;
`;

const Subtitle = styled.h2`
  font-size: 2em;
  margin-bottom: 1em;
  color: #333;
`;

const SectionTitle = styled.h3`
  font-size: 1.5em;
  margin-top: 1.5em;
`;

const Paragraph = styled.p`
  margin-bottom: 1em;
`;

const List = styled.ul`
  margin-left: 20px;
  margin-bottom: 1.5em;
`;

const ListItem = styled.li`
  margin-bottom: 0.5em;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  margin: 1.5em 0;
`;

const RecycleComputerMonitor = () => {
  return (
    <BlogContainer>
      <Title>Where Can I Recycle Computer Monitors?</Title>
      <Subtitle>Responsible Disposal Options for Your Old Monitor</Subtitle>

      <Paragraph>
        Disposing of an old computer monitor can be challenging, but recycling it is the most eco-friendly option. So, where can you recycle computer monitors? This guide will cover the best places to recycle your monitor, ensuring it’s handled responsibly.
      </Paragraph>

      <Image src={computer} alt="Where to recycle computer monitors" />
      
      <List>
          <ListItem>
          <strong><a href="https://www.tforge.co/recycle" target="_blank" rel="noopener noreferrer">Terraforge</a></strong> Terraforge offers the easiest way to recycle your old laptops within minutes. Simply select how much you need to send in, and we'll recycle it for free. We provide a 100% data privacy guarantee to ensure your data is completely wiped as soon as it arrives. The best part, if you're laptop can be refurbished, we'll pay you for what it's worth! 
          </ListItem>
      </List>

      <SectionTitle>1. Manufacturer Recycling Programs</SectionTitle>
      <Paragraph>
        Many computer manufacturers offer recycling programs where you can send your old monitor back for proper disposal. For instance, <strong><a href="https://www.dell.com/learn/us/en/uscorp1/corp-comm/environment_dell_recycling" target="_blank" rel="noopener noreferrer">Dell</a></strong> and <strong><a href="https://www.samsung.com/us/aboutsamsung/sustainability/environment/responsible-recycling/programs/" target="_blank" rel="noopener noreferrer">Samsung</a></strong> have established programs to recycle monitors and other electronics.
      </Paragraph>
      <List>
        <ListItem>
          <strong>Dell:</strong> Dell’s recycling program allows you to send your monitor back to them, and they will ensure it’s recycled responsibly.
        </ListItem>
        <ListItem>
          <strong>Samsung:</strong> Samsung’s recycling program offers convenient drop-off locations for monitors and other electronics.
        </ListItem>
      </List>

      <SectionTitle>2. Electronics Retailer Recycling</SectionTitle>
      <Paragraph>
        Retailers like <strong><a href="https://www.bestbuy.com/site/services/recycling/pcmcat149900050025.c?id=pcmcat149900050025" target="_blank" rel="noopener noreferrer">Best Buy</a></strong> and <strong><a href="https://www.staples.com/sbd/cre/marketing/sustainability-center/recycling-services/electronics/" target="_blank" rel="noopener noreferrer">Staples</a></strong> also provide recycling services for computer monitors. These stores make it easy to drop off your monitor and have it recycled for free or a small fee.
      </Paragraph>
      <List>
        <ListItem>
          <strong>Best Buy:</strong> Best Buy accepts a wide range of electronics for recycling, including monitors. You can bring your monitor to the store, and they’ll take care of the recycling.
        </ListItem>
        <ListItem>
          <strong>Staples:</strong> Staples also accepts monitors for recycling. They may charge a small fee for large items, but it’s a convenient option for safe disposal.
        </ListItem>
      </List>

      <SectionTitle>3. Local E-Waste Recycling Facilities</SectionTitle>
      <Paragraph>
        Local e-waste recycling centers are another excellent option for recycling your monitor. These facilities specialize in the safe disposal of electronics, including monitors. You can search for a nearby e-waste center using resources like <strong><a href="https://earth911.com/" target="_blank" rel="noopener noreferrer">Earth911</a></strong>.
      </Paragraph>
      <Paragraph>
        Many communities also host e-waste recycling events where you can drop off your old monitor and other electronics for free recycling.
      </Paragraph>

      <SectionTitle>4. Charitable Donations</SectionTitle>
      <Paragraph>
        If your monitor is still in working condition, consider donating it to a charitable organization. Organizations like <strong><a href="https://www.goodwill.org" target="_blank" rel="noopener noreferrer">Goodwill</a></strong> and <strong><a href="https://www.salvationarmyusa.org" target="_blank" rel="noopener noreferrer">The Salvation Army</a></strong> accept monitor donations, which they may refurbish or recycle. This option not only keeps your monitor out of the landfill but also helps those in need.
      </Paragraph>

      <SectionTitle>Preparing Your Monitor for Recycling</SectionTitle>
      <Paragraph>
        Before recycling your monitor, take these steps to prepare it:
      </Paragraph>
      <List>
        <ListItem>Unplug and disconnect the monitor from all devices.</ListItem>
        <ListItem>Wipe down the monitor to remove any dust or debris.</ListItem>
        <ListItem>Check with the recycling program for any specific requirements, such as removing the stand or cables.</ListItem>
      </List>

      <SectionTitle>Conclusion</SectionTitle>
      <Paragraph>
        Recycling your old computer monitor is a responsible and environmentally friendly way to dispose of it. Whether you choose a manufacturer recycling program, a retailer, a local e-waste facility, or a charitable donation, there are plenty of options available to ensure your monitor is recycled properly. By recycling your monitor, you’re helping to reduce electronic waste and protect the environment.
      </Paragraph>

      <Paragraph>
        For more information on recycling electronics and promoting sustainable practices, explore our other articles. Together, we can make a positive impact on the planet.
      </Paragraph>
    </BlogContainer>
  );
};

export default RecycleComputerMonitor;
