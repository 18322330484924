import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import TforgeLogo2 from '../assets/tforgelogo3.png';
import popupImg from "../assets/popup.png"

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: black;
  z-index: 1000;
`;

const Banner = styled.div`
  background-color: #3C3C3C; /* Replace with your color */
  color: white;
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  flex-direction: row; /* Stack items vertically */
  text-align: center; /* Center text horizontally */
`;

const PopupMenu = styled.div`
  display: ${props => (props.$visible ? "block" : "none")};
  position: fixed; /* Change to fixed to center it */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the element */
  background-color: #3C3C3C;
  color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Ensure it stays on top */
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: ${props => (props.$visible ? "1" : "0")};
  transform: ${props => (props.$visible ? "translate(-50%, -50%) scale(1)" : "translate(-50%, -50%) scale(0.95)")};

  width: 90%; /* Adjusts the width on mobile to be 90% of the viewport */
  max-width: 400px; /* Adds a max-width to prevent it from getting too large on larger screens */

  @media (max-width: 768px) {
    width: 80%; /* Slightly reduce width on smaller screens */
  }

`;

const PopupImage = styled.img`
  width: 100%; /* Adjust to fit within the popup */
  max-width: 300px; /* Limit the maximum width */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 15px;
/* Space between the image and text */
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
`;

const Label = styled.label`
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: white;
  margin: 10px 0;
  text-align: left;
`;

const Input = styled.input`
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;/* Adjust for padding */
  margin-bottom: 20px;
  box-sizing: border-box;
`;



const SubmitButton = styled.button`
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  padding: 10px 20px;
  width: 100%;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;


const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #ffffff; /* Color of the X icon */
  cursor: pointer;
  z-index: 1002; /* Ensure it's above the popup content */
  
  &:hover {
    color: #555; /* Optional hover color */
  }
`;

const WaitlistButton = styled.button`
  background-color: #4caf4c; /* Replace with your button color */
  color: #ffffff;
  border: none;
  padding: 3px 7px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px; /* Space between text and button */

  &:hover {
    background-color: #388e3c; /* Adjust hover color as needed */
  }
`;

const Overlay = styled.div`
  display: ${props => (props.$visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Blur effect */
  z-index: 1000; /* Below the popup but above the page content */
`;


const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
`;

const LogoImage = styled.img`
  width: 150px;
  height: auto;
`;

const NavLogo = styled(NavLink)`
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
`;

const NavMenu = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: black;
    position: absolute;
    top: 100%;
    left: 0;
    padding: 20px;
    box-sizing: border-box;

    &.active {
      display: flex;
    }
  }
`;

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`;

const NavItem = styled.li`
  margin-right: 20px;

  @media (max-width: 768px) {
    margin: 10px 0;
    text-align: center;
  }
`;

const NavLinkStyled = styled(NavLink)`
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;

  &:hover {
    color: #ccc;
  }
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 1100; /* Ensure it stays on top */

  & div {
    width: 25px;
    height: 3px;
    background-color: #ffffff;
    margin: 3px 0;
    transition: all 0.3s ease;
  }

  @media (max-width: 768px) {
    display: flex;
    margin-right: 10px;
  }
`;

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [deviceWaitlist, setDeviceWaitlist] = useState([])
  const [waitlistEmail, setWaitListEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [waitlistError, setWaitlistError] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [popupVisible, setPopupVisible] = useState(false);
  const popupRef = useRef(null);

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setPopupVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleDeviceWaitlist = (e, device) => {
    setDeviceWaitlist((prevSelectedDevices) =>
      prevSelectedDevices.includes(device)
        ? prevSelectedDevices.filter((d) => d !== device)
        : [...prevSelectedDevices, device]
    );
    // Your existing logic here...
    console.log(deviceWaitlist)
  };

  function handleSubmit(e){
      e.preventDefault();
      if (!waitlistEmail) {
        setWaitlistError(true);
        return;
      }
      fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          waitlist_email: waitlistEmail,
        }),
      })
      .then(response => response.text())
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
      setSubmitted(true)      
  }


  return (
    <Header>
      <Nav>
        <NavLogo to="/">
          <LogoImage src={TforgeLogo2} />
        </NavLogo>

        <Hamburger onClick={toggleMenu}>
          <div />
          <div />
          <div />
        </Hamburger>

        <NavMenu className={menuOpen ? 'active' : ''} id="nav-menu">
          <NavList>
            <NavItem>
              <NavLinkStyled to="/" onClick={() => setMenuOpen(false)}>
                Home
              </NavLinkStyled>
            </NavItem>
            <NavItem>
              <NavLinkStyled to="/recycle" onClick={() => setMenuOpen(false)}>
                Recycle
              </NavLinkStyled>
            </NavItem>
            <NavItem>
              <NavLinkStyled to="/about" onClick={() => setMenuOpen(false)}>
                About
              </NavLinkStyled>
            </NavItem>
            <NavItem>
              <NavLinkStyled to="/contact" onClick={() => setMenuOpen(false)}>
                Contact
              </NavLinkStyled>
            </NavItem>
          </NavList>
        </NavMenu>
      </Nav>
      <>
      <Overlay $visible={popupVisible} />
      <Banner>
        <span><strong>Have working devices you want to sell? </strong></span>
        <WaitlistButton onClick={togglePopup}>Join Waitlist</WaitlistButton>
      </Banner>
      <PopupMenu ref={popupRef} $visible={popupVisible}>
        <CloseButton onClick={() => setPopupVisible(false)}>×</CloseButton>
          <PopupImage src={popupImg}/>
          {!submitted && (
          <>
          <h2>Get ready to earn cash from your electronics! Upcycling is launching soon — join our waitlist.</h2>
          </>
          )}
          <Container>
            {submitted ? <div>
              <p><strong>Thank you! We'll be in touch. You'll receive an email as soon as upcycling is live.</strong></p>
              <p>Feel free to recycle any unwanted electronics - we'll still give you a quote once they've been received.</p>
            </div> : 
            <Form onSubmit={(e) => handleSubmit(e)}>
            <Label htmlFor="email"><strong>Join the waitlist.</strong></Label>
            <Input id="email" type="email" placeholder="Enter your email" value={waitlistEmail} onChange={(e) => setWaitListEmail(e.target.value)}/>
             {/* <br />
              <Label><strong>What devices would you be interested in getting a quote for?</strong></Label>
         <DeviceOptions>
              <DeviceOption
              isSelected={deviceWaitlist.includes("Cellphone")} 
              onClick={(e) => handleDeviceWaitlist(e, "Cellphone")}>
              Cellphones
              </DeviceOption>
              <DeviceOption
              isSelected={deviceWaitlist.includes("Laptop")} 
              onClick={(e) => handleDeviceWaitlist(e, "Laptop")}>
                Laptops
            </DeviceOption>
            <DeviceOption 
            isSelected={deviceWaitlist.includes("Tablet")}
            onClick={(e) => handleDeviceWaitlist(e, "Tablet")}>
             Tablets
            </DeviceOption>
            <DeviceOption
            isSelected={deviceWaitlist.includes("Gaming")} 
            onClick={(e) => handleDeviceWaitlist(e, "Gaming")}>
            Gaming Consoles   
            </DeviceOption>
            <DeviceOption
            isSelected={deviceWaitlist.includes("Other")} 
            onClick={(e) => handleDeviceWaitlist(e, "Other")}>
            Other    
            </DeviceOption>
        </DeviceOptions> */}
              <SubmitButton>Submit</SubmitButton>
              {waitlistError && (<p>please submit a valid email.</p>)}
            </Form>
            }
          
          </Container>
        </PopupMenu>
    </>
    </Header>
  );
};

export default Navbar;




