import React from 'react';
import { Outlet } from 'react-router-dom';

const BlogLayout = () => {
  return (
    <div>
      {/* You can add a blog-specific navbar or any other common components here */}
      <Outlet />
    </div>
  );
};

export default BlogLayout;
