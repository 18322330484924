import React, { useState } from "react";
import styled from 'styled-components';


// Styled Components
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 100px 20px 20px 20px;
  font-family: 'Roboto', sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5em;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;

const FormContainer = styled.div`
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormTitle = styled.h2`
  font-size: 1.5em;
  color: #555;
  margin-bottom: 20px;
`;

const Input = styled.textarea`
  padding: 15px;
  font-size: 1em;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
`;

const EmailInput = styled.textarea`
  padding: 15px;
  font-size: 1em;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  font-size: 1.2em;
  color: #fff;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const FAQSection = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FAQTitle = styled.h1`
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
`;

const FAQItem = styled.div`
  margin-bottom: 15px;
`;

const Question = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ToggleIcon = styled.span`
  font-size: 1.5em;
  margin-left: 10px;
`;

const Answer = styled.div`
  font-size: 1em;
  margin-top: 10px;
  padding-left: 20px;
  color: #666;
`;



function Contact(){
  const [faqVisibility, setFaqVisibility] = useState({});
  const [contactQuestion, setContactQuestion] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [contactError, setContactError] = useState(false)

  const toggleFAQ = (index) => {
    setFaqVisibility((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const faqs = [
    {
      question: "How can I track my items?",
      answer: "You can track your order using the tracking number provided in the shipping confirmation email."
    },
    {
      question: "I didn't receive a shipping label after submitting my request.",
      answer: "Please send us an email at support@tforge.co if a shipping label is not received."
    },
    {
      question: "Is there anything that can't be shipped?",
      answer: "Please refer to current federal guidelines on items that cannot be shipped. All shipping labels provided are ground carriers."
    },
    {
      question: "What happens to my items after they're received?",
      answer: "Items are immediately diagnosed to ensure all data is safely wiped before proceeding with processing and recycling."
    },
    {
      question: "Can I get my items back after they have shipped?",
      answer: "Once items have been sent for shipping, we cannot guarantee item-recovery. Please email support@tforge.co for any questions or support."
    },
    {
      question: "Do I need to delete my data before I ship my items?",
      answer: "For safety and security; we always reccommend deleting and restoring your device to factory settings before shipping."
    },
  ];

  function handleFeedback(e){
    e.preventDefault();
    if (!contactEmail || !contactQuestion) {
      setContactError(true);
      return;
    }
    fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/contact_support', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          contact_question: contactQuestion,
          contact_email: contactEmail
        }),
      })
      .then(response => response.text())
      .then(data => console.log(data))
      .catch(error => console.error('Error:', error));
      setSubmitted(true)
      setContactError(false)  
  }

  return (
    <Container>
      <Title>Contact us</Title>
      
      <> 
    <FormContainer>
    {submitted ? <FormTitle> Thank you! We will be in touch soon.</FormTitle> : 
      <Form onSubmit={(e) => handleFeedback(e)}>
        <FormTitle>Have a question or feedback? We'd love to hear from you.</FormTitle>
        <Input placeholder="Your Message" value={contactQuestion} onChange={(e) => setContactQuestion(e.target.value)}/>
        <label>What's the best email for you?</label>
        <EmailInput placeholder="Your email" value={contactEmail} onChange={(e)=> setContactEmail(e.target.value)} />
        <SubmitButton type="submit">Submit</SubmitButton>
        {contactError && (<p>Please fill out all fields before submitting.</p>)}
      </Form>
       }
    </FormContainer>
    </>
    <FAQSection>
      <FAQTitle>Frequently Asked Questions</FAQTitle>
      {faqs.map((faq, index) => (
        <FAQItem key={index}>
          <Question onClick={() => toggleFAQ(index)}>
            {faq.question}
            <ToggleIcon>{faqVisibility[index] ? '-' : '+'}</ToggleIcon>
          </Question>
          {faqVisibility[index] && <Answer>{faq.answer}</Answer>}
        </FAQItem>
      ))}
    </FAQSection>
  </Container>

  )
}

export default Contact;