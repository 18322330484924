import react from 'react'
import styled from 'styled-components';
import brokentv from '../assets/brokentv.png'
import DeviceLife from './DeviceLifeLanding';

const Container = styled.div`
  padding: 100px 20px 20px; /* Adjust padding to ensure it's not blocked by the navbar */
  font-family: 'Roboto', sans-serif; /* Change font to a more techy style */
  color: #333;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;

  h2 {
    color: #0cc0df;
    text-align: center;
  }

  h1 {
    margin-top: 70px;
    font-size: 2.5em;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 2em;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.2em;
    text-align: justify;
    margin-bottom: 20px;
  }

  ul {
    padding-left: 20px;
  }

  ul li {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    padding: 60px 15px 15px;
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
    p {
      font-size: 1em;
    }
  }
`;

const Banner = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

function BrokenTv() {
  return (
    <Container>
    <h1>How to Get Rid of a Broken or Old TV: A Guide Series on how to Reduce E-Waste</h1>
    <Banner>
      <img src={brokentv}/>
    </Banner>
    <p><i>Ryan Rash - August 6th 2024</i></p>
    <p>In today’s world, televisions are among the most frequently replaced household items. Whether your TV is broken, outdated, or you’re upgrading to a newer model, knowing how to properly dispose of your old TV is crucial to avoid fines, stay healthy, and help our planet!</p>
    <h2>Why Proper Disposal Matters</h2>
    <p>Simply put, our health and the environment. When TVs are thrown into landfills or dumps, the toxic chemicals they contain leach into the soil and groundwater, posing serious environmental and health risks. Lead, for example, can cause damage to the nervous system, kidneys, and brain, especially in children. Mercury, another hazardous substance, can lead to poisoning and other severe health effects. Beyond health concerns, improperly disposed of TVs contribute to the depletion of valuable resources such as copper, silver, and gold, which could be recovered and reused through proper recycling methods. So you may be asking yourself, How CAN I dispose of my TV without fear?</p>
    <h2>How to PROPERLY Dispose of Your Old TV</h2>
    <p>1. <strong>Manufacturer Take-Back Programs:</strong> Many TV manufacturers offer take-back programs, where they will recycle your old TV free of charge when you purchase a new one. Some brands, such as Samsung and LG, have extensive recycling networks that make it easy to dispose of your old device responsibly.</p>
    <p>2. <strong>Electronic Retailers:</strong> Major retailers like Best Buy and Staples have recycling programs that accept old electronics, including TVs. They often have drop-off locations or even offer pickup services, making it convenient to recycle your device.</p>
    <p>3. <strong>E-Waste Recycling Centers:</strong> Many communities have e-waste recycling centers where you can drop off your old TV. These centers specialize in safely dismantling and recycling electronic devices to ensure that hazardous materials are handled correctly.</p>
    <p>4. <strong>Local Government Programs:</strong> Some municipalities have special e-waste collection events or designated drop-off locations for electronic devices. Check with your local waste management service for options available in your area.</p>
    <p>5. <strong>TerraForge Recycling Service:</strong> TerraForge offers an easy and environmentally friendly way to recycle your old TV. They send you prepaid shipping labels so you can ship your electronic waste directly to their facility at no cost to you. This service ensures that your TV is recycled properly, without the hassle of finding a drop-off location.</p>
    <h2>The Impact of Proper Disposal</h2>
    <p>Properly disposing of your old TV can significantly reduce the environmental impact of electronic waste. Yay! Recycling TVs allows these valuable materials to be reclaimed and allows us to forge a new and brighter future. Doing your part to recycle these products reduces the need for raw material extraction and prevents harmful chemicals from polluting the environment. By taking responsibility for our electronic waste, we can help mitigate the growing e-waste crisis and move towards a more sustainable future. The next time you upgrade your television, think twice before tossing your old one in the trash. With numerous disposal options available, including services like TerraForge you can use without leaving home, there’s no reason to contribute to the growing e-waste problem. Instead, you can help solve it! By choosing to recycle or repurpose your old TV, you’re not only protecting the environment but also conserving valuable resources for future generations. Consider yourself a genius.</p>
    <DeviceLife />
  </Container>
  );
}

export default BrokenTv;
