import React, {useState, useEffect} from 'react'
import axios from 'axios'
import box from "../assets/box.png"
import shield from "../assets/shield.png";
import notFound from "../assets/notfound.png"
import {Button} from "../styles/about.styles"
    import styled from 'styled-components';
    import MailIcon from "../assets/002-email.png"
import RecycleIcon from "../assets/001-recycle.png"
import Micro from "../assets/micro.svg"
import Midi from "../assets/midi.svg"
import Macro from "../assets/macro.svg"


    const Body = styled.div`
    font-family: 'Roboto', sans-serif;
    margin-top: 90px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Align content at the top */
    padding: 10px; /* Add padding for spacing */
    overflow-y: auto; /* Enable vertical scrolling if needed */
`;

const DeviceButton = styled.button`
    border: none;
    font-size: 16px;
    color: #fff;
    padding: 8px 16px;
    background-color: #4070f4;
    border-radius: 6px;
    margin-top: 20px; /* Adjust the top margin as needed */
    align-self: center; /* Align the button to the center horizontally */
    align-items: center; /* Center the content vertically */
    cursor: pointer;
    transition: all 0.3s ease;
    
    &:hover {
        background-color: #265df2;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; /* Center-align the content */
    margin-top: 10px;
    margin-bottom: 10px;
     /* Add some space above the loading message */
`;



const LoadingText = styled.p`
    font-size: 16px;
    color: #666;
    margin-top: 10px; /* Add some space between the image and the text */
`;

const Spinner = styled.div`
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;

const QuoteSpinner = styled.div`
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;

const HeadingContainer = styled.div`
    text-align: center; /* Center-align the content */
    margin-bottom: 1px;
    margin-top: 30px; /* Add some space between the headings and the input field */
`;


const Title = styled.h1`
    font-size: 38px; /* Adjust the font size as needed */
    margin-bottom: 10px; /* Add some space below the h1 */
`;

const Question = styled.h1`
    font-size: 24px; /* Adjust the font size as needed */
    margin-bottom: 10px; /* Add some space below the h1 */
`;

const TitleH2 = styled.h2`
    font-size: 18px; /* Adjust the font size as needed */
    color: #666; /* Set the color to a subdued gray */
`;

const H1 = styled.h1`
    font-size: 18px; /* Adjust the font size as needed */
    margin-bottom: 10px; /* Add some space below the h1 */
`;

const H2 = styled.h2`
    font-size: 14px; /* Adjust the font size as needed */
    color: #666; /* Set the color to a subdued gray */
`;

const DeviceContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center align device cards */
`;

const DeviceCard = styled.div`
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
    width: 300px;
    transition: transform 0.3s ease;
    cursor: pointer;
    border: 2px solid transparent; /* Add transparent border by default */

    &:hover {
        transform: translateY(-5px);
    }

    ${({ $isselected }) =>
        $isselected &&
        `
        border: 2px solid blue; /* Change border color to blue when selected */
    `}
`;

const DeviceDetail = styled.p`
    font-family: 'Roboto', sans-serif; /* Use a modern and sleek font */
    font-size: 16px; /* Optimal font size for readability */
    color: #333; /* Dark gray color for high contrast */
    margin-bottom: 8px; /* Consistent margin for spacing */
`;

const BoldDetail = styled.p`
    font-family: 'Roboto', sans-serif;
    font-weight: bold; /* Use a modern and sleek font */
    font-size: 18px; /* Optimal font size for readability */
    color: #333; /* Dark gray color for high contrast */
    margin-bottom: 8px; /* Consistent margin for spacing */
`;

const RecycleDetail = styled.p`
    font-family: 'Roboto', sans-serif;
    font-weight: bold; /* Use a modern and sleek font */
    font-size: 18px; /* Optimal font size for readability */
    color: #333; /* Dark gray color for high contrast */
    margin-top: 2px;
    margin-bottom: 2px; /* Consistent margin for spacing */
    padding: 0px;
`;

const RecycleInfo = styled.p`
    font-family: 'Roboto', sans-serif; /* Use a modern and sleek font */
    font-size: 16px; /* Optimal font size for readability */
    color: #333; /* Dark gray color for high contrast */
    margin-top: 4px;
    margin-bottom: 8px; /* Consistent margin for spacing */
`;

const OptionContainer = styled.div`
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center; /* Center align device cards */
`
const OptionCard = styled.div`
     background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
    width: 300px;
    transition: transform 0.3s ease;
    cursor: pointer;
    border: 2px solid transparent; /* Add transparent border by default */

    &:hover {
        transform: translateY(-5px);
    }

    ${({ $isselected }) =>
        $isselected &&
        `
        border: 2px solid blue; /* Change border color to blue when selected */
    `}
`
const OptionName = styled.p`
    font-family: 'Poppins', sans-serif; /* Use Poppins font */
    font-size: 18px; /* Increase font size */
    color: #333;
    margin-bottom: 10px;
    font-weight: bold;
`

const OptionDetail = styled.li`
    font-size: 14px;
    color: #666;
    margin-top: 5px;
`
const OptionCardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
`;
const InterestContainer = styled.div`
  display: flex;
  flex-direction: column; /* Render interests in a single column */
  align-items: center; /* Center-align interests */
  padding: 0; /* Ensure no extra padding */

  /* Add media query for mobile responsiveness */
  @media (max-width: 768px) {
    padding: 10px; /* Add some padding for better spacing on mobile */
  }
`;

const QuestionContainer = styled.div`
  text-align: center; /* Center-align the content */
  margin-bottom: 10px; /* Adjust bottom margin for spacing */
  margin-top: 20px; /* Add some space between the headings and the input field */
`;

const InterestCard = styled.div`
  background-color: #fff; 
  border-radius: 20px; /* Larger border radius for a sleek look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  margin: 20px; /* Spacing between cards */
  padding: 20px; /* Padding inside the card */
  width: 100%; /* Make it responsive */
  max-width: 400px; /* Set maximum width for consistency */
  cursor: pointer;
  transition: transform 0.3s ease;
  border: 2px solid transparent; /* Transparent border by default */

  &:hover {
    transform: translateY(-5px); /* Lift card slightly on hover */
  }

  ${({ $isselected }) =>
    $isselected &&
    `
    border: 2px solid #4070f4; /* Blue border when selected */
  `}

  /* Add media query for mobile responsiveness */
  @media (max-width: 768px) {
    width: 90%; /* Adjust width for mobile */
    margin: 10px; /* Reduce margin for better spacing on mobile */
  }
`;
const InterestName = styled.p`
    font-family: 'Poppins', sans-serif; /* Use Poppins font */
    font-size: 20px; /* Slightly larger font size */
    color: #333;
    margin-bottom: 10px; /* Spacing between name and detail */
    font-weight: bold;
`;

const InterestDetail = styled.p`
    font-size: 16px; /* Font size for option details */
    color: #666;
    margin-top: 5px; /* Spacing between details */
`;

const SummaryContainer = styled.div`
    
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
`;

const SummaryHeading = styled.h1`
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
`;

const DeviceSummary = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 30%; /* Adjusted width */

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    width: 90%; /* Full width on smaller screens */
    margin-right: 0;
    margin-bottom: 20px; /* Add some spacing between the stacked elements */
  }

  h2 {
    font-size: 24px;
    color: #555; /* Tech company's brand color */
    margin-bottom: 10px;
    border-bottom: 2px solid #6bb37e; /* Underline color */
    padding-bottom: 5px; /* Add some space between text and underline */
  }

  p {
    font-size: 18px;
    
    margin-bottom: 1px;
  }

  strong {
    color: #555; /* Sustainability accent color */
  }
`;

const ShippingForm = styled.form`
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    label {
        display: block;
        margin-bottom: 10px;
    }

    input[type="text"] {
        width: calc(100% - 20px);
        padding: 10px;
        font-size: 16px;
        margin-bottom: 10px;
    }

    button {
        background-color: #007bff;
        color: #fff;
        border: none;
        padding: 10px 20px;
        font-size: 18px;
        border-radius: 5px;
        cursor: pointer;
    }
`;

const ProcessContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 20px; /* Adjust margin as needed */

    @media (max-width: 768px) {
        flex-direction: column; /* Stack elements vertically on smaller screens */
    }
`;

const Separator = styled.div`
    width: 90%;
    height: 0;
    border-top: 1px solid #e0e0e0; /* Subtle gray color */
    margin: 20px 0; /* Adjust margin as needed */
`;

const SummarySeparator = styled.div`
    width: 100%;
    height: 0;
    border-top: 1px solid #e0e0e0; /* Subtle gray color */
    margin: 20px 0; /* Adjust margin as needed */
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column; /* Align items in a column */
  max-width: 400px; /* Control maximum width */
  width: 90%; /* Occupy 90% of the available width */
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  margin: 20px auto; /* Center form and add top margin */
  
  @media (max-width: 768px) {
    padding: 15px; /* Adjust padding for mobile */
  }
`;

const LoginField = styled.div`
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 15px; /* Adjust margin-bottom for mobile */
  }
`;

const LoginLabel = styled.label`
  font-weight: bold;
  display: block;
  margin-bottom: 5px; /* Add some space below the label */

  @media (max-width: 768px) {
    font-size: 14px; /* Adjust font size for mobile */
    margin-bottom: 3px; /* Adjust margin-bottom for mobile */
  }
`;

const LoginInputField = styled.input`
  width: 90%; /* Full width */
  padding: 12px; /* Increased padding for better aesthetics */
  border: 1px solid #ccc;
  border-radius: 4px;

  @media (max-width: 768px) {
    padding: 10px; /* Adjust padding for mobile */
    font-size: 14px; /* Adjust font size for mobile */
  }
`;

const LoginSubmitButton = styled.button`
  padding: 12px 20px; /* Adjust padding for a better button size */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
  
  &:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }

  @media (max-width: 768px) {
    padding: 10px 15px; /* Adjust padding for mobile */
    font-size: 14px; /* Adjust font size for mobile */
  }
`;

const LoggedInHeader = styled.div`
    text-align: center;
    margin-bottom: 20px;
`;

const ThankYouMessage = styled.p`
    font-size: 1.2em;
    margin-bottom: 10px;
`;

const WrapUpMessage = styled.h2`
    font-size: 1.5em;
    margin-bottom: 20px;
`;

const StyledList = styled.ul`
  flex: 1;
  margin-left: 20px;
  margin-right: 10px;
  width: 48%; /* Adjusted width */

  @media (max-width: 768px) {
    width: 80%; /* Full width on smaller screens */
    margin-left: 0px;
    margin-bottom: 20px; /* Add some spacing between the stacked elements */
  }
`;
const StyledListItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  margin-right: 10px;
`;

const WhatsNextIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const Content = styled.div`
  flex-grow: 1;
`;

const WhatsNextTitle = styled.strong`
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
`;

const WhatsNextShield = styled.strong`
  color: #0cc0df;
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Description = styled.p`
  margin: 0;
`;

const ErrorMessage = styled.div`
    color: #ff0000; /* Red color for error messages */
    font-size: 14px; /* Adjust font size as needed */
    margin-top: 5px; /* Add some margin to separate it from other elements */
`;

const StyledSpan = styled.span`
  cursor: pointer; /* Change cursor to pointer to indicate interactivity */
  color: blue; /* Set color to blue to make it stand out as a clickable element */
  text-decoration: underline; /* Add underline to make it look like a link */
  /* Optionally, add more styles to customize the appearance further */
`;

const PopupContainer = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: ${props => (props.isVisible ? 'block' : 'none')};
  top: 200px;  /* Adjust as needed */
  left: 600px; /* Adjust as needed */

  /* Add media query for mobile responsiveness */
  @media (max-width: 768px) {
    width: 90%; /* Adjust width for mobile */
    left: 50%; /* Center horizontally */
    top: 150px; /* Adjust top position for mobile */
    transform: translateX(-50%); /* Center horizontally */
  }
`;

const HowItWorksLink = styled.span`
  color: blue;
  cursor: pointer;
  text-decoration: underline;
`;

const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: row; /* Default to row */
  justify-content: space-between;
  margin: 20px;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack on smaller screens */
  }
`;

const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const DeviceCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items horizontally */
  gap: 8px; /* Adjust the spacing between the card and button */
`;


    function RecycleForm() {
        const [loading, setLoading] = useState(false);
        const [selectedDevices, setSelectedDevices] = useState([]);
        const [pageCount, setPageCount] = useState(1)
        const [shippingDetails, setShippingDetails] = useState({
            fullName: '',
            address: '',
            city: '',
            state: '',
            country: '',
            postalCode: ''
        });
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        const [currentUser, setCurrentUser] = useState();
        const [registrationToggle, setRegistrationToggle] = useState(false);
        const [recycleKit, setRecycleKit] = useState('')
        const [kitRoute, setKitRoute] = useState("")
        const [shipRoute, setShipRoute] = useState("")
        const smallKit = 'price_1P9bLwEQljKfhE8e7bhPREDu'
        const mediumKit = 'price_1P9ZJiEQljKfhE8e3XLugoTV'
        const largeKit = 'price_1P9bGPEQljKfhE8encU8YwWm'
        const smallGround = 'shr_1P9YtbEQljKfhE8efqBlAoWf'
        const [selectedKits, setSelectedKits] = useState(null)
        const [isLoggedIn, setIsLoggedIn] = useState(false)
        const [errorMessage, setErrorMessage] = useState('');


        useEffect(() =>{
             // Calculate total weight in grams
        const totalWeightGrams = selectedDevices.reduce((total, device) => {
            // Convert each device weight to grams and add to total
            return total + device.weight // Assuming device weight is in pounds
        }, 0);

        // Determine recycling kit value based on total weight
        let kitValue = '';
        if (totalWeightGrams < 5) { // Less than 5 pounds
            kitValue = '3.99';
            setKitRoute(smallKit);
            setShipRoute(smallGround);
        } else if (totalWeightGrams <= 10) { // Between 5 and 10 pounds
            kitValue = '7.99';
            setKitRoute(mediumKit);
            setShipRoute(smallGround);
        } else { // Greater than 10 pounds
            kitValue = '11.99';
            setKitRoute(largeKit);
            setShipRoute(smallGround);
        }

        // Set the recycling kit value
        setRecycleKit(kitValue);
        }
        ,[selectedDevices])
         
        
    
  

          const handleRegister = (e) => {
            e.preventDefault();
            const normalizedEmail = email.trim().toLowerCase(); // Normalize email to lowercase
            fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/register', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email: normalizedEmail, password }),
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error('Failed to register');
              }
            })
            .then(data => {
              
          
              // After successful registration, log the user in
              handleSubsequentLogin(normalizedEmail, password); // Pass email and password to the login function
          
              // Store the authentication token if you receive one
              localStorage.setItem('authToken', data.token);
            })
            .catch(error => {
              console.error('Error:', error); // Log any errors to the console
              // Handle errors here (e.g., show an error message)
              setErrorMessage('Invalid email or password. Please try again.');
            });
          };
          
          const handleSubsequentLogin = (email, password) => {
            
            fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/login', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email, password }),
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error('Failed to log in');
              }
            })
            .then(data => {
              setCurrentUser(data)
              setIsLoggedIn(true); // Log the response data to the console
              // Handle successful login, e.g., redirect to a different page or store user data
              localStorage.setItem('authToken', data.token); // Store the authentication token
            })
            .catch(error => {
              console.error('Error:', error); // Log any errors to the console
              // Handle errors here (e.g., show an error message)
              setErrorMessage('Login failed. Please try again.');
            });
          };

          const handleLogin = (e) => {
            e.preventDefault();
            fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/login', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email, password }),
            })
            .then(response => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error('Failed to log in');
              }
            })
            .then(data => {
              setCurrentUser(data)
              setIsLoggedIn(true); // Log the response data to the console
              // Handle successful login, e.g., redirect to a different page or store user data
              localStorage.setItem('authToken', data.token); // Store the authentication token
            })
            .catch(error => {
              console.error('Error:', error); // Log any errors to the console
              // Handle errors here (e.g., show an error message)
              setErrorMessage('Login failed. Please try again.');
            });
          };
        
        
        function setShipping(e) {
            e.preventDefault();
            setLoading(true);
            fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/create_shipping', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fullName: shippingDetails.fullName,
                    address: shippingDetails.address,
                    city: shippingDetails.city,
                    postalCode: shippingDetails.postalCode,
                    userEmail: currentUser.email
                })
            })
            .then(function(response) {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to create shipping instance');
                }
            })
            .then(function(data) {
                
                createItems(data.shipping_id);
            })
            .catch(function(error) {
                console.error("Error creating shipping instance:", error.message);
            });
        }
        
        function createItems(shipping_id) {
            // Iterate over selectedDevices and create items for each device
            let totalDeviceWeight = selectedDevices.reduce((total, device) => total + device.weight, 0);
            selectedDevices.forEach(function(device) {
                // Create an item for the current device
                fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/create_item', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        shipping_id: shipping_id,
                        brand: device.brand,
                        condition: device.condition,
                        interest: device.interest,
                        model: device.model,
                        type: device.type,
                        weight: device.weight,
                        year: device.year 
                    })
                })
                .then(function(response) {
                    if (!response.ok) {
                        throw new Error('Failed to create item instance');
                    }
                })
                .catch(function(error) {
                    console.error("Error creating item instance:", error.message);
                });
            })
             setLabel(totalDeviceWeight);
        }

        function setLabel(totalDeviceWeight) {
            fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/create_label_referral', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fullName: shippingDetails.fullName,
                    address: shippingDetails.address,
                    city: shippingDetails.city,
                    state: shippingDetails.state, 
                    postalCode: shippingDetails.postalCode,
                    userEmail: currentUser.email, 
                    totalWeight: totalDeviceWeight
                })
            })
            .then(function(response) {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to create shipping instance');
                }
            })
            .then(function(data) {
                window.location.href = '/ordered';
                setLoading(false) // Redirect to /ordered page
            })
            .catch(function(error) {
                console.error("Error creating shipping instance:", error.message);
            });
        }

        const handleInputChange = (e) => {
            const { name, value } = e.target;
            setShippingDetails(prevState => ({
                ...prevState,
                [name]: value
            }));
        };

        function handleKitSelection(e){
            e.preventDefault()
            if (selectedKits === 'small')
                setSelectedDevices([{
                    brand: 'Standard',
                    condition: 'Perfect for small handheld electronics like smartphones and portable devices.',
                    interest: 'Recycle',
                    model: 'Small Devices',
                    type: 'Send up to 4 lbs.',
                    weight: 4,
                    year: '2024', 
                    image: Micro   
                }])
            else if(selectedKits === 'medium')
            setSelectedDevices([{
                brand: 'Standard',
                condition: 'Suitable for household devices like laptops and larger tablets.',
                interest: 'Recycle',
                model: 'Medium Devices',
                type: 'Send up to 10 lbs.',
                weight: 9,
                year: '2024', 
                image: Midi   
            }])
            else 
            setSelectedDevices([{
                brand: 'Standard',
                condition: 'Designed to hold multiple devices or larger electronic devices.',
                interest: 'Recycle',
                model: "Large Devices",
                type: 'Send up to 20 lbs.',
                weight: 19,
                year: '2024',
                image: Macro   
            }])
            setPageCount(4)
            
        }

        
        return (
<Body>
  {pageCount === 1 && (
    <Container>
      <HeadingContainer>
        <Title>E-waste made easy.</Title>
        <TitleH2>Start recycling your electronics within minutes.</TitleH2>
        <Separator />
        <ProcessContainer>
          <DeviceCardWrapper>
            <DeviceCard
              $isselected={selectedKits === 'small'}
              onClick={() => setSelectedKits('small')}
            >
              <img src={Micro} alt="Small Devices" />
              <RecycleDetail>Small Devices</RecycleDetail>
              <RecycleInfo>Send up to 4 lbs.</RecycleInfo>
              <i>Perfect for small handheld electronics like smartphones and portable devices.</i>
            </DeviceCard>
            {selectedKits === 'small' && <Button onClick={handleKitSelection}>Recycle</Button>}
          </DeviceCardWrapper>
          <DeviceCardWrapper>
            <DeviceCard
              $isselected={selectedKits === 'medium'}
              onClick={() => setSelectedKits('medium')}
            >
              <img src={Midi} alt="Medium Devices" />
              <RecycleDetail>Medium Devices</RecycleDetail>
              <RecycleInfo>Send up to 10 lbs.</RecycleInfo>
              <i>Suitable for household devices like laptops and larger tablets.</i>
            </DeviceCard>
            {selectedKits === 'medium' && <Button onClick={handleKitSelection}>Recycle</Button>}
          </DeviceCardWrapper>
          <DeviceCardWrapper>
            <DeviceCard
              $isselected={selectedKits === 'large'}
              onClick={() => setSelectedKits('large')}
            >
              <img src={Macro} alt="Large Devices" />
              <RecycleDetail>Large Devices</RecycleDetail>
              <RecycleInfo>Send up to 20 lbs.</RecycleInfo>
              <i>Designed to hold multiple devices or larger electronic devices.</i>
            </DeviceCard>
            {selectedKits === 'large' && <Button onClick={handleKitSelection}>Recycle</Button>}
          </DeviceCardWrapper>
        </ProcessContainer>
      </HeadingContainer>
    </Container>
  )}

  {pageCount === 4 && (
    <SummaryContainer>
      <HeadingContainer>
        <Title>Perfect! We're almost done.</Title>
        <SummarySeparator />
      </HeadingContainer>

      <CheckoutContainer>
        <DeviceSummary>
          {selectedDevices.map((device, index) => (
            <div key={index}>
              <ResponsiveImage src={device.image} alt="Large Devices" />
              <RecycleDetail>{device.model}</RecycleDetail>
              <RecycleInfo>{device.type}</RecycleInfo>
              <i>{device.condition}</i>
              <p style={{ fontSize: '10px' }}>
                Note: All items will be reviewed for upcycling. You will receive a quote within 3-5 business days when items are received. We do not guarantee upcycling on any items.
              </p>
            </div>
          ))}
        </DeviceSummary>
        <StyledList>
          <H1>What's Next?</H1>

          <StyledListItem>
            <IconWrapper>
              <WhatsNextIcon src={MailIcon} alt="Shipping Label Generation" />
            </IconWrapper>
            <Content>
              <WhatsNextTitle>Shipping Label</WhatsNextTitle>
              <Description>Once your order is processed, we'll generate a shipping label and send it to you via email.</Description>
            </Content>
          </StyledListItem>
          <StyledListItem>
            <IconWrapper>
              <WhatsNextIcon src={box} alt="Shipment" />
            </IconWrapper>
            <Content>
              <WhatsNextTitle>Send it</WhatsNextTitle>
              <Description>Drop off your items at your nearest shipping provider or schedule a pickup.</Description>
            </Content>
          </StyledListItem>
          <StyledListItem>
            <IconWrapper>
              <WhatsNextIcon src={RecycleIcon} alt="Recycling" />
            </IconWrapper>
            <Content>
              <WhatsNextTitle>Recycle</WhatsNextTitle>
              <Description>Once we receive your electronics, we'll safely recycle them according to environmental regulations.</Description>
            </Content>
          </StyledListItem>
          <StyledListItem>
            <IconWrapper>
              <WhatsNextIcon src={shield} alt="shield" />
            </IconWrapper>
            <Content>
              <WhatsNextShield>100% Data Protection</WhatsNextShield>
              <Description><i> We ensure all of your data is safely wiped from your device before processing it for recycling.</i></Description>
            </Content>
          </StyledListItem>
        </StyledList>
      </CheckoutContainer>
      <SummarySeparator />
      {isLoggedIn ? (
        <LoggedInHeader>
          <ThankYouMessage>Thank you for logging in!</ThankYouMessage>
          <WrapUpMessage>Let's wrap up and get your items shipped.</WrapUpMessage>
          <SummarySeparator />
        </LoggedInHeader>
      ) : (
        <LoginForm onSubmit={(e) => registrationToggle ? handleLogin(e) : handleRegister(e)}>
          {!registrationToggle ? (<H1>Create an Account</H1>) : ""}
          <LoginField>
            <LoginLabel htmlFor="email">Email</LoginLabel>
            <LoginInputField type="text" id="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
          </LoginField>
          <LoginField>
            <LoginLabel htmlFor="password">Password</LoginLabel>
            <LoginInputField type="password" id="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
          </LoginField>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <p>
            {registrationToggle ? "Need to create an account?" : "Already have an account?"}
            <StyledSpan onClick={() => setRegistrationToggle(!registrationToggle)}>
              <br></br>{registrationToggle ? " Create an Account" : "Sign in"}
            </StyledSpan>
          </p>
          <LoginSubmitButton type="submit">{registrationToggle ? "Login" : "Register"}</LoginSubmitButton>
        </LoginForm>
      )}
      {isLoggedIn && (
        <>
          <SummaryHeading>Shipping Details</SummaryHeading>
          <ShippingForm onSubmit={(e) => setShipping(e)} >
            <label>
              Full Name:
              <input type="text" name="fullName" value={shippingDetails.fullName} onChange={handleInputChange} required />
            </label>
            <label>
              Address:
              <input type="text" name="address" value={shippingDetails.address} onChange={handleInputChange} required />
            </label>
            <label>
              City:
              <input type="text" name="city" value={shippingDetails.city} onChange={handleInputChange} required />
            </label>
            <label>
              State:
              <input type="text" name="state" value={shippingDetails.state} onChange={handleInputChange} required />
            </label>
            <label>
              Postal Code:
              <input type="text" name="postalCode" value={shippingDetails.postalCode} onChange={handleInputChange} required />
            </label>
            <button type="submit" disabled={loading}>
              {loading ? 'Loading...' : 'Submit'}
            </button>
            {loading && (<p><i>Do not refresh page. This can take up to 10 seconds.</i></p>)}
          </ShippingForm>
        </>
      )}
    </SummaryContainer>
  )}
</Body>

        );
    }

export default RecycleForm;