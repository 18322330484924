import React from 'react';
import styled from 'styled-components';
import Raccoon from '../assets/raccoon.png';
import {useNavigate} from 'react-router-dom';


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 30px; /* Add some padding for better spacing on mobile */

  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for smaller screens */
  }
`;


const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 2rem; /* Smaller font size for mobile */
  }
`;

const Subtitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1.5rem; 
    text-align: center;/* Smaller font size for mobile */
  }
`;

const Image = styled.img`
  width: 50%; /* Adjust the width as needed */

  @media (max-width: 768px) {
    width: 80%; /* Wider width for mobile */
  }
`;

const RecycleButton = styled.button`
  padding: 15px 30px;
  font-size: 1.2rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }

  @media (max-width: 768px) {
    padding: 12px 25px; /* Adjust padding for mobile */
    font-size: 1rem; /* Smaller font size for mobile */
  }
`;

function OrderConfirm() {
    
    const navigate = useNavigate();

  const handleBackToHome = () => {
    // Navigate back to the home page
    navigate('/');
  };





  function logout() {
    fetch('https://terraforge-backend-4aaed00c0cf5.herokuapp.com/logout', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => {
        if (response.ok) {
            // Clear any client-side session storage if needed
            console.log('Logout successful');
            // Redirect the user to the login page or home page
            window.location.href = '/'; // Adjust this based on your routing
        } else {
            console.error('Logout failed');
        }
    })
    .catch(error => {
        console.error('Error:', error);
    });
}

    return (
        <PageContainer>
            <Title>Your Order is confirmed</Title>
            <Subtitle>Thank you for your Trash! You'll receive an email with your shipping label shortly.</Subtitle>
           
            <Image src={Raccoon} alt="Raccoon" />
            
            <RecycleButton onClick={handleBackToHome}>Back to home</RecycleButton>
            
        </PageContainer>
    );
}

export default OrderConfirm;
