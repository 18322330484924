import React from 'react'
import Recycler from "../assets/landingrecycler.svg"
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  text-align: center;
`;

const Description = styled.h3`
  font-size: 1.6rem;
  margin-bottom: 30px;
`;

const Image = styled.img`
  width: 100%;
  max-width: 1000px; /* Adjust max-width as needed */
  margin-bottom: 30px;
`;

const RecycleButton = styled.button`
  padding: 15px 30px;
  font-size: 1.2rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }
`;


const DeviceLife = () => {

    const navigate = useNavigate();

    const handleToRecycle = () => {
      // Navigate back to the home page
      navigate('/recycle');
    };
  return (
    <SectionContainer>
      <Description>We're here to help you get rid of old electronics that deserve to be sustainably recycled.</Description>
      <Image src={Recycler} alt="Recycler" />
      <RecycleButton onClick={handleToRecycle}>Recycle now</RecycleButton>
    </SectionContainer>
  );
};

export default DeviceLife;