import React from 'react'
import styled from 'styled-components'
import raccoons from "../assets/RaccoonData3.png"
import { useNavigate } from 'react-router-dom';


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  margin-bottom: 30px;
  margin-top: 0px;
 
  
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Image = styled.img`
  max-width: 50%;
  height: auto;
  margin-right: 2rem;
  border-radius: 10px;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

const TextContainer = styled.div`
  max-width: 50%;

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
  }
`;

const Heading = styled.h1`
  font-size: 3rem;
  color: #333;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  font-size: 1.2rem;
  color: #555;
  line-height: 1.5;
`;

const LearnMoreButton = styled.button`
  padding: 15px 30px;
  font-size: 1.2rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }
`;

function DataPrivacyLanding(){

    const navigate = useNavigate();

    const handleToAbout = () => {
      // Navigate back to the home page
      navigate('/about');
    };

    return(
        <Container>
      <Image src={raccoons} alt="Terraforge - Electronics Recycling" />
      <TextContainer>
        <Heading>Data Privacy. Guaranteed.</Heading>
        <Text>
         We make it easier than ever to recycle your electronics responsibly. Our mission is to safeguard your data, ensure proper recycling of electronic devices, and help you declutter your space sustainably.</Text>
      </TextContainer>
    </Container>
    )
}

export default DataPrivacyLanding;