import react from 'react'
import styled from 'styled-components';
import critical from "../assets/criticalewaste.png"

const Container = styled.div`
  padding: 100px 20px 20px; /* Adjust padding to ensure it's not blocked by the navbar */
  font-family: 'Roboto', sans-serif; /* Change font to a more techy style */
  color: #333;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;

  h2 {
    color: #0cc0df;
    text-align: left;
  }

  h1 {
    margin-top: 70px;
    font-size: 2.5em;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 2em;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.2em;
    text-align: justify;
    margin-bottom: 20px;
  }

  ul {
    padding-left: 20px;
  }

  ul li {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    padding: 60px 15px 15px;
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
    p {
      font-size: 1em;
    }
  }
`;

const Banner = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

function WhyEwasteCritical(){

    return (
        <Container>
            <h1>Why Electronic Recycling is Crucial for the Environment</h1>
            <p><i>By Gianni Cherry - August 7th 2024</i></p>
            <p>In our modern world, technology evolves rapidly, and with it, the turnover of electronic devices increases. From smartphones to laptops, televisions to kitchen appliances, electronic waste, or e-waste, is becoming one of the fastest-growing waste streams globally. As consumers, it's essential to understand why electronic recycling is crucial for the environment and what we can do to mitigate the adverse effects of e-waste.
            </p>
            <h2>The Growing Problem of E-Waste</h2>
            <Banner>
                <img src={critical}/>
            </Banner>
            <p>E-waste encompasses a wide range of discarded electronic devices. According to the Global E-Waste Monitor, the world generated a staggering 53.6 million metric tons of e-waste in 2019, with only 17.4% of this properly recycled. The rest ends up in landfills or is improperly managed, leading to significant environmental and health hazards.
            </p>
            <h2>Environmental Hazards of Improper Disposal</h2>
            <h3><strong>1. Toxic Chemicals and Heavy Metals:</strong> </h3>
            <p>Electronic devices contain hazardous materials like lead, mercury, cadmium, and brominated flame retardants. When e-waste is improperly disposed of, these toxins can leach into the soil and groundwater, causing severe environmental pollution.
            Lead can damage the nervous system, kidneys, and brain, particularly in children. Mercury exposure can lead to serious health issues, including kidney damage and developmental problems.</p>
            <h3><strong>2. Resource Depletion:</strong></h3>
            <p>E-waste contains valuable materials such as gold, silver, copper, and rare earth elements. Mining these resources anew is energy-intensive and environmentally damaging. Recycling electronics allows these precious materials to be recovered and reused, reducing the need for new mining activities.
            </p>
            <h3><strong>3. Greenhouse Gas Emissions:</strong></h3>
            <p>Manufacturing new electronic devices from raw materials requires a significant amount of energy, contributing to greenhouse gas emissions. Recycling helps conserve energy by reducing the need for raw material extraction and processing, thus lowering carbon footprints.</p>
            <h2>Benefits of Electronic Recycling</h2>
            <h3><strong>Conserving Natural Resources:</strong></h3>
            <p>Recycling electronics allows for the recovery of valuable metals and components, which can be reused to manufacture new products. This conserves natural resources and reduces the environmental impact of mining and processing raw materials.
            </p>
            <h3><strong>Reducing Pollution:</strong></h3>
            <p>Proper recycling prevents hazardous substances from contaminating the environment. Specialized recycling facilities can safely manage and dispose of toxic materials, minimizing the risk of soil and water pollution.
            </p>
            <h3><strong>Energy Savings:</strong></h3>
            <p>Recycling e-waste is often more energy-efficient than producing new electronics from raw materials. This leads to significant energy savings and helps combat climate change by reducing greenhouse gas emissions.</p>
            <h3><strong>Economic Benefits:</strong></h3>
            <p>The e-waste recycling industry creates jobs and stimulates economic growth. By fostering a circular economy, recycling can provide economic opportunities while promoting sustainable development.</p>
            <h2>What You Can Do</h2>
            <h3><strong>Recycle Responsibly:</strong></h3>
            <p>Use certified e-waste recycling facilities or recycle direcly from home with Terraforge to ensure your old devices are processed safely and efficiently. Many manufacturers and retailers offer take-back programs to help consumers recycle their electronics responsibly.</p>
            <h3><strong>Donate or Sell:</strong></h3>
            <p>If your device is still functional, consider donating or selling it. Many organizations and charities accept used electronics to refurbish and distribute to those in need.</p>
            <h3><strong>Educate and Advocate:</strong></h3>
            <p>Spread awareness about the importance of e-waste recycling. Advocate for stronger regulations and support initiatives that promote sustainable electronics management.</p>
            <h2>Conclusion</h2>
            <p>Electronic recycling is not just an option; it's a necessity for protecting our environment and health. By responsibly recycling our electronic devices, we can reduce pollution, conserve valuable resources, and make a positive impact on our planet. As technology continues to advance, let us all commit to making sustainable choices and supporting a greener, more sustainable future.</p>
        </Container>
    )
}

export default WhyEwasteCritical;