import react from 'react'
import styled from 'styled-components';
import critical from "../assets/criticalewaste.png"
import reset from "../assets/resetdata.png"
import DeviceLife from './DeviceLifeLanding';

const Container = styled.div`
  padding: 100px 20px 20px; /* Adjust padding to ensure it's not blocked by the navbar */
  font-family: 'Roboto', sans-serif; /* Change font to a more techy style */
  color: #333;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;

  h2 {
    color: #0cc0df;
    text-align: left;
  }

  h1 {
    margin-top: 70px;
    font-size: 2.5em;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 2em;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.2em;
    text-align: justify;
    margin-bottom: 20px;
  }

  ul {
    padding-left: 20px;
  }

  ul li {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    padding: 60px 15px 15px;
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
    p {
      font-size: 1em;
    }
  }
`;

const Banner = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

function DataWipe(){

    return (
        <Container>
            <h1>A Factory Reset to Set You Up for Success: How to safely remove your data before recycling</h1>
            <Banner>
                <img src={reset}/>
            </Banner>
            <p>Restoring a device to its factory settings is a crucial step when troubleshooting persistent issues, preparing your gadget for a new owner, for recycling or upcycling, or simply wanting a fresh start. 
                While the exact process varies across different types of devices, this article provides step-by-step instructions for restoring most devices to their factory settings for whatever reason you may need to do it.</p>
            <h2>1. Android Devices</h2>
            <h3><strong>Method 1: From Settings</strong> </h3>
            <p><strong>Backup Your Data:</strong> Begin by safeguarding your important files. Navigate to Settings > System > Backup and ensure your data is securely stored.</p>
            <p><strong>Factory Reset:</strong>Once your data is backed up, go to Settings > System > Reset options > Erase all data (factory reset). Confirm the action by entering your PIN or password.
            </p>
            <h3><strong>Method 2: Using Recovery Mode</strong></h3>
            <p><strong>1. Turn off the device.</strong></p>
            <p><strong>2. Enter Recovery Mode:</strong> Press and hold the Power and Volume Down buttons simultaneously until the recovery menu appears.</p>
            <p><strong>3. Factory Reset:</strong></p> Use the volume buttons to navigate to Wipe data/factory reset and press the Power button to confirm.
            <h2>2. iOS Devices (iPhone/iPad)</h2>
            <h3><strong>Method 1: From Settings</strong></h3>
            <p><strong>1. Backup Your Data:</strong>Go to Settings > [your name] > iCloud > iCloud Backup > Back Up Now to ensure your data is safe.</p>
            <p><strong>2. Factory Reset:</strong> Head to Settings > General > Reset > Erase All Content and Settings. Enter your passcode and Apple ID password to finalize the process</p>
            <h3><strong>Method 2: Using iTunes/Finder</strong></h3>
            <p><strong> 1. Sync your device:</strong> Connect your device to a computer using a USB cable.</p>
            <p><strong>2. Restore via iTunes/Finder:</strong> Open iTunes (or Finder on macOS Catalina and later), select your device, and click Restore iPhone (or iPad).</p>
            <h2>3. Windows PC</h2>
            <p><strong>1. Backup Your Data:</strong> Navigate to Settings > Update & Security > Backup to create a backup of your files.</p>
            <p><strong>2. Factory Reset:</strong> Go to Settings > Update & Security > Recovery. Under Reset this PC, click Get started and choose Remove everything for a complete reset.</p>
            <h2>4. Mac</h2>
            <p><strong>1. Backup Your Data:</strong> Use Time Machine or another preferred backup method.</p>
            <p><strong>2. Factory Reset:</strong> Restart your Mac while holding down Command + R to enter Recovery Mode. Select Disk Utility, erase your hard drive, and then reinstall macOS.</p>
            <h2>5. Gaming Consoles</h2>
            <p><strong>PlayStation 4/5:</strong> Go to Settings > System > Reset Options > Reset Your Console.</p>
            <p><strong>Xbox One/Series X/S:</strong> Navigate to Settings > System > Console info > Reset Console.</p>
            <h2>6. Routers</h2>
            <p> 1. Locate the reset button on your router, usually a small hole.</p>
            <p><strong>2. Factory Reset:</strong> Use a paperclip to press and hold the button for about 10-15 seconds. The router will reboot and restore to factory settings.</p>
            <h2>8. Smart Home Devices</h2>
            <p><strong>Amazon Echo:</strong> Press and hold the Action button for 25 seconds until the device resets.</p>
            <p><strong>Google Home:</strong> Press and hold the Mute button for 15 seconds to restore factory settings.</p>
            <h2>9. Wearable Devices</h2>
            <p><strong>Apple Watch: </strong>Go to Settings > General > Reset > Erase All Content and Settings.</p>
            <p><strong>Fitbit:</strong> Head to Settings > About > Factory Reset to restore the device.</p>
            <h2>10. Other Electronics</h2>
            <p>For other devices like e-readers, cameras, or Bluetooth speakers, refer to the user manual or the manufacturer’s website for specific instructions on how to perform a factory reset.</p>
            <h2>Let’s Wrap it Up</h2>
            <p>Restoring your device to factory settings can resolve issues and prepare it for a new life. While the steps vary by device, this SHOULD cover the most common gadgets you might need to reset. Always ensure your data is backed up before proceeding, and follow the manufacturer’s instructions for a smooth reset process.</p>
            <DeviceLife />
        </Container>
    )
}

export default DataWipe;