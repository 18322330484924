import react from 'react'
import styled from 'styled-components'
import unusual from '../assets/unusualasset.png'
import DeviceLife from './DeviceLifeLanding';

const Container = styled.div`
  padding: 100px 20px 20px; /* Adjust padding to ensure it's not blocked by the navbar */
  font-family: 'Roboto', sans-serif; /* Change font to a more techy style */
  color: #333;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;

  h2 {
    color: #0cc0df;
    text-align: left;
  }

  h1 {
    margin-top: 70px;
    font-size: 2.5em;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 2em;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.2em;
    text-align: justify;
    margin-bottom: 20px;
  }

  ul {
    padding-left: 20px;
  }

  ul li {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    padding: 60px 15px 15px;
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
    p {
      font-size: 1em;
    }
  }
`;

const Banner = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;




function HoardingHabits (){
    return(
        <Container>
            <h1>The Great Electronic Graveyard: A Quick Guide to Our Hoarding Habits</h1>
            <Banner>
                <img src={unusual}/>
            </Banner>
            <p><i>Ryan Rash - August 6th 2024</i></p>
            <p>We’ve all been there—standing in the middle of our garage or attic, staring at a pile of old electronics that would make a tech museum jealous. You know the ones: the relics of a bygone era, each with a tangled mess of cables and a slightly musty smell that screams, “I haven’t been turned on in years!” 
                So, let’s take a light-hearted tour of the most commonly hoarded old and broken electronics. Maybe, just maybe, we’ll inspire you to let go… or at least make some space for your next set of outdated gadgets. 
            </p>
            <h2>1. Cell Phones: The Digital Fossils</h2>
            <p>
                Why do we keep them? Because someday, when the apocalypse comes, that Nokia brick might be the only thing still working. Or maybe it’s because we’re convinced we’ll need the contacts from our 2007 flip phone. Either way, our drawers are full of these digital fossils, along with every charger, case, and accessory we ever bought for them. 
            </p>
            <h2>2. Laptops and Computers: The Data Dinosaurs </h2>
            <p>
            That old laptop sitting in the closet? It’s basically a prehistoric beast. It’s slow, it’s heavy, and it probably takes a good five minutes to boot up. But we can’t throw it out because there might be a photo of our cat somewhere there. Or that half-finished novel we were totally going to finish someday. Spoiler: we’re not finishing it. 
            </p>
            <h2>3. Chargers and Cables: The Spaghetti Monster </h2>
            <p>
            Behold the dreaded tangle of cables! We all have that one box or drawer that’s an untangleable knot of chargers, USB cables, and adapters. We’re afraid to get rid of any of them because one day, we might find the device they go with. Newsflash: that device is long gone, and even if you find it, it probably doesn’t work anymore.
            </p>
            <h2>4. Televisions: The Ancient Relics </h2>
            <p>
            Remember when having a big, boxy TV meant you were living the high life? Now, those relics are stashed in basements and garages, taking up valuable real estate because we can’t bear to part with them. Maybe it’s the nostalgia, or maybe we’re just not ready to admit that modern TVs are way better (and lighter).
            </p>
            <h2>5. Remote Controls: The Wand of Forgotten Powers</h2>
            <p>
            Somewhere in your home, there’s a drawer with half a dozen remote controls, none of which belong to anything you currently own. But we keep them because what if we accidentally discover a time machine and need to adjust the volume on a TV from 1995?
            </p>
            <h2>6. Printers and Scanners: The Paperweights of Doom</h2>
            <p>
            Old printers and scanners are the ultimate hoard. They don’t work, they’re massive, and they’re heavy. But getting rid of them feels like admitting defeat. Maybe it’s because we spent so much on ink cartridges. Maybe it’s because we’re convinced we’ll need to print a photo someday… as if that day will ever come. 
            </p>
            <h2>7. Game Consoles and Controllers: The Portal to Retro Gaming </h2>
            <p>
            We keep old game consoles because they’re like a portal to our childhood. Even if they don’t work, they hold the memories of late-night gaming sessions and sore thumbs. Plus, who knows? Maybe retro gaming will make a comeback (again) and we’ll be ready to cash in. 
            </p>
            <h2>8. MP3 Players and iPods: The Soundtrack of Yesteryear </h2>
            <p>Remember when carrying 1,000 songs in your pocket was mind-blowing? Now those MP3 players and iPods are gathering dust in a drawer somewhere. We keep them because they remind us of simpler times when shuffling songs was our biggest concern. Also, we’re convinced they’ll be worth a fortune someday—just like Beanie Babies. 
            </p>
            <h2>9. VCRs and DVD Players: The Original Binge-Watching Machines </h2>
            <p>
            VCRs and DVD players were our first taste of binge-watching. Now they sit unused, surrounded by stacks of tapes and discs we’ll never watch again. But we keep them because getting rid of them feels like throwing away a piece of our past—one that involves a lot of rewinding and fast-forwarding. 
            </p>
            <h2>10. Cameras: The Time Capsules </h2>
            <p>Old digital cameras and even film cameras are like time capsules of our lives. We keep them because they captured moments we don’t want to forget—even if those moments are now trapped on memory cards we’ve lost or film rolls we never developed. 
            </p>
            <h2>11. Hard Drives and USB Drives: The Digital Black Boxes </h2>
            <p>Hard drives and USB drives are the ultimate hoarded items. We keep them because they’re like digital black boxes, holding all the secrets of our past—photos, documents, and files we’re too afraid to delete but too lazy to actually back up. We tell ourselves we’ll organize them one day. Spoiler: we won’t. 
            </p>
            <h2>12. Appliances with Electronics: The Forgotten Soldiers </h2>
            <p>Old microwaves, alarm clocks, and other small appliances are the forgotten soldiers of our electronic hoarding habit. They sit in basements and garages, waiting for a second
            chance that will never come. But we keep them because they served us well… until they didn’t. 
            </p>
            <h2>The Takeaway</h2>
            <p>In the end, our hoarded electronics are like little time capsules of our lives, each with a story to tell. But maybe it’s time to let some of them go—after all, there’s only so much space, and there are always new gadgets to hoard. Besides, who needs a VCR when you’ve got streaming? 
                A new set of gadgets and tech means new solutions to our hoarding nightmares and more space in your garage or attic. 
                Consider TerraForge, the company created to care for your old electronic friends and help send them to Electronic Heaven, a place where they will be properly discarded, reused, and recycled into what they can be next! 
                With a data privacy guarantee and team dedicated to handling your previously loved purchases with care, our team will do all we can to ensure you’re moving waste out and moving on to the next big thing.</p>
            <DeviceLife />
        </Container>
    )
}

export default HoardingHabits;