import React, { useState } from 'react';
import styled from 'styled-components';
import Recycler from "../assets/landingrecycler.svg";
import Blog from "./Blogs";
import IntroVideo from './IntroVideo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Paragraph = styled.p`
  font-size: 1.2em;
  line-height: 1.6;
  color: #555;
  text-align: left; /* Changed from justify to left */
  max-width: 100%; /* Ensure it spans the width of the container on mobile */
  margin: 20px auto;
  padding: 0 15px;

  text-indent: 2em; /* Indent the first line */

  @media (max-width: 768px) {
    padding: 0 15px; /* Keep padding consistent on mobile */
    text-indent: 1em; /* Slightly smaller indent on mobile for better readability */
  }
`;

const Header = styled.h1`
  margin-top: 60px;
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
`;

const Image = styled.img`
  width: 100%;
  max-width: 750px; /* Adjust max-width as needed */
  margin-bottom: 30px;
`;

const ReadMore = styled.span`
  color: #007BFF;
  cursor: pointer;
  margin-left: 5px;
`;

function About() {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const paragraphText = `
    E-waste, also known as electronic waste, is considered an item that has been discarded with no intention of further use. 
    This could be something as seemingly insignificant as a phone cord or as large as a desktop computer. 
    Unfortunately, E-waste typically doesn't end up in the right places. E-waste requires proper handling and recycling 
    to ensure materials can be separated in a safe and ethical manner. The current issue is the potential toxicity that separates it from traditional waste. 
    Over 70% of our toxic waste is from electronics. E-waste could potentially contain toxic contaminants such as mercury, lead, and barium. 
    If not properly handled, e-waste runs the risk of contaminating and polluting the environment.
    When E-waste reaches third-world countries, the easiest way to reach the valued materials inside electronics is to burn the plastic, 
    leaving the small amount of precious metals left. Not only does this release harmful fumes into the air, 
    but the potential health risks of these recyclers are severe. So instead of allowing our old electronics to collect dust in storage, 
    be discarded, or contaminate our environment, we aim to ensure our e-waste is ethically handled, repurposed, and recycled for a more sustainable future.
  `;

  return (
    <Container>
      <Header>A Little About Electronics</Header>
      <Image src={Recycler} alt="Recycler" />
      <Paragraph>
        {isExpanded ? paragraphText : `${paragraphText.slice(0, 304)}...`}
        <ReadMore onClick={toggleReadMore}>
          {isExpanded ? "Show Less" : "Read More"}
        </ReadMore>
      </Paragraph>
      <IntroVideo />
      <Header>Recent blog posts</Header>
      <Blog/>
    </Container>
  );
}

export default About;

