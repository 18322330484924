import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components'
// Centered Div


// Form
const LoginDiv = styled.div`
margin-top: 100px;
margin-bottom: 100px;
  text-align: center;
  
`;

const LoginForm = styled.form`
  width: 300px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  margin: auto;
`;

const LoginField = styled.div`
  margin-bottom: 20px;
`;

const LoginLabel = styled.label`
  font-weight: bold;
  display: block;
`;

const LoginInputField = styled.input`
  width: calc(100% - 20px);
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const LoginSubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const client = axios.create({
  baseURL: "http://127.0.0.1:8000"
});

function Account(){

    const [currentUser, setCurrentUser] = useState();
    const [registrationToggle, setRegistrationToggle] = useState(false);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [csrfToken, setCsrfToken] = useState(null);

    useEffect(() => {
      // Fetch CSRF token from Django backend
      fetch('http://localhost:8000/gettoken/')
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.error('Error fetching CSRF token:', error);
        });
    }, []);

    useEffect(() => {
        client.get("/user/")
        .then(function(res) {
            console.log(res)
          setCurrentUser(true);
        })
        .catch(function(error) {
            console.log(error)
          setCurrentUser(false);
        });
      }, []);

    
    function getCookie(name) {
        const cookieValue = document.cookie.split('; ')
            .find(cookie => cookie.startsWith(name + '='))
            ?.split('=')[1];
        return cookieValue ? decodeURIComponent(cookieValue) : null;
    }

    function handleRegistration(e) {
        e.preventDefault();
        client.post(
          "/register/",
          {
            email: email,
            username: username,
            password: password
          }
        ).then(function(res) {
          client.post(
            "/login/",
            {
              email: email,
              password: password
            }
          ).then(function(res) {
            setCurrentUser(true);
          });
        });
      }


      return (
        <LoginDiv>
    <LoginForm onSubmit={(e) => handleRegistration(e)}>
    <LoginField>
      <LoginLabel htmlFor="email">Email address</LoginLabel>
      <LoginInputField type="email" id="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
      <small>We'll never share your email with anyone else.</small>
    </LoginField>
    {registrationToggle && (
      <LoginField>
        <LoginLabel htmlFor="username">Username</LoginLabel>
        <LoginInputField type="text" id="username" placeholder="Enter username" value={username} onChange={e => setUsername(e.target.value)} />
      </LoginField>
    )}
    <LoginField>
      <LoginLabel htmlFor="password">Password</LoginLabel>
      <LoginInputField type="password" id="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
    </LoginField>
    <p>Don't have an account? <a onClick={() => setRegistrationToggle(!registrationToggle)}>Register now.</a></p>
    <LoginSubmitButton type="submit">{registrationToggle ? "Register" : "Login"}</LoginSubmitButton>
  </LoginForm>
  </LoginDiv>
      )
}

export default Account