import React from 'react'
import Main from './Main'
import Counter from './Counter'
import DeviceLife from './DeviceLifeLanding'
import IntroVideo from './IntroVideo'
import DataPrivacyLanding from './RaccoonData'
import Why from './Why'
import "../styles/Home.css"
import Hero from '../components/Hero'



function Home(){
    return (
        <div>
            <Main />
                
                <Why />
                <Hero />
                <DataPrivacyLanding />
                <IntroVideo />
                <Counter />
                <DeviceLife />              
        </div>
    )
}

export default Home