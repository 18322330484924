import react from 'react'
import styled from 'styled-components';
import critical from "../assets/criticalewaste.png"
import batteries from "../assets/batteries.png"

const Container = styled.div`
  padding: 100px 20px 20px; /* Adjust padding to ensure it's not blocked by the navbar */
  font-family: 'Roboto', sans-serif; /* Change font to a more techy style */
  color: #333;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;

  h2 {
    color: #0cc0df;
    text-align: left;
  }

  h1 {
    margin-top: 70px;
    font-size: 2.5em;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 2em;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.2em;
    text-align: justify;
    margin-bottom: 20px;
  }

  ul {
    padding-left: 20px;
  }

  ul li {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    padding: 60px 15px 15px;
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
    p {
      font-size: 1em;
    }
  }
`;

const Banner = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

function BatteryRecycling(){

    return (
        <Container>
            <h1>How to Safely Dispose of Batteries: A Comprehensive Guide</h1>
            <Banner>
                <img src={batteries}/>
            </Banner>
            <p><i>By Gianni Cherry - August 8th 2024</i></p>
            <p>In today's world, batteries power almost everything around us, from smartphones and laptops to remote controls and electric vehicles. However, when it comes time to dispose of these batteries, it’s essential to do so safely and responsibly. Improper disposal can lead to environmental pollution and even pose health risks. This guide will walk you through the importance of proper battery disposal and how to do it safely.            </p>
            <h2>Why Proper Battery Disposal Matters</h2>
            <p>Batteries contain a variety of heavy metals and toxic chemicals, including lead, mercury, cadmium, and lithium. When disposed of improperly, these substances can leak into the environment, contaminating soil and water sources. This pollution can harm wildlife, plants, and even humans. Moreover, some types of batteries, like lithium-ion batteries, are prone to overheating and can cause fires if they’re not handled correctly.
            </p>
            <h2>Types of Batteries and How to Dispose of Them</h2>
            <h3><strong>1. Alkaline Batteries (AA, AAA, C, D, 9V)</strong> </h3>
            <p>Alkaline batteries are the most common household batteries. In many places, these batteries can be disposed of with regular household waste because they contain fewer toxic materials. However, recycling is still the best option. Many stores and recycling centers offer drop-off bins specifically for alkaline batteries.</p>
            <h3><strong>2. Rechargeable Batteries (NiMH, NiCd, Li-ion)</strong></h3>
            <p>Rechargeable batteries, including those used in electronics like laptops and phones, should never be thrown away with regular trash. These batteries contain hazardous materials and should be taken to a designated recycling center or participating retail stores that offer recycling programs. Some municipalities also provide curbside pickup for rechargeable batteries.
            </p>
            <h3><strong>3. Button Cell Batteries</strong></h3>
            <p>Button cell batteries are commonly used in watches, hearing aids, and small electronics. These batteries often contain mercury and should be disposed of at a hazardous waste collection site or through a specialized recycling program.</p>
            <h3><strong>4. Lead-Acid Batteries (Car Batteries)</strong></h3>
            <p>Car batteries are highly toxic and should never be disposed of in regular trash. These batteries must be taken to an auto shop or recycling center that accepts lead-acid batteries. Many places will offer a credit or a discount on a new battery when you return your old one.
            </p>
            <h3><strong>5. Lithium-Ion Batteries</strong></h3>
            <p>Lithium-ion batteries are found in smartphones, laptops, and other portable electronics. These batteries should be recycled at a specialized facility to prevent environmental contamination and reduce the risk of fire. Many electronics retailers have recycling programs for lithium-ion batteries.
            </p>
            <h2>Steps to Safely Dispose of Batteries</h2>
            <h3><strong>1. Identify the Battery Type</strong></h3>
            <p>Before disposing of a battery, identify what type it is. This will determine the best disposal method and whether the battery can be recycled</p>
            <h3><strong>2. Store Batteries Safely Before Disposal</strong></h3>
            <p>Store used batteries in a cool, dry place away from flammable materials. Keep them in a non-conductive container, such as a plastic or cardboard box, to prevent accidental discharge or short-circuiting.</p>
            <h3><strong>3. Find a Recycling Center or Drop-Off Location</strong></h3>
            <p>Check with local recycling centers, retail stores, or your municipality for battery recycling programs. Many communities have drop-off points for household batteries, and some retailers offer in-store recycling bins.</p>
            <h3><strong>4. Prepare Batteries for Recycling</strong></h3>
            <p>For rechargeable and lithium-ion batteries, it’s a good idea to tape over the terminals to prevent them from short-circuiting during transport. This is especially important for larger batteries, such as those used in power tools or electric vehicles.</p>
            <h3><strong>5. Never Throw Batteries in the Trash</strong></h3>
            <p>Regardless of the type of battery, throwing it in the trash should be avoided. Many batteries contain toxic substances that can harm the environment if not disposed of properly.</p>
            <h2>The Impact of Responsible Battery Disposal</h2>
            <p>Recycling batteries not only prevents harmful chemicals from entering the environment but also allows valuable materials to be recovered and reused. Metals like cobalt, nickel, and lead can be extracted from old batteries and repurposed in new products, reducing the need for mining and conserving natural resources.
              By taking the time to dispose of batteries properly, you’re contributing to a healthier planet and helping to reduce the environmental impact of electronic waste. It’s a small but significant step towards a more sustainable future.</p>
            <p>Proper battery disposal is crucial for protecting our environment and health. Whether you’re dealing with everyday alkaline batteries or more complex lithium-ion batteries, following the right disposal methods ensures that these energy sources are managed safely and sustainably. Remember, recycling is always the best option, and many resources are available to help you dispose of batteries responsibly.</p>
        </Container>
    )
}

export default BatteryRecycling;