import Navbar from "../src/components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About"
import Recycle from "./components/RecycleForm"
import Footer from "./components/Footer"
import Account from "./components/Account"
import Contact from "./components/Contact"
import ScrollToTop from "./components/ScrollToTop";
import Blog from './components/Blogs'
import BrokenTv from "./components/BlogPost";
import UnusualItems from "./components/BlogPost2";
import HoardingHabits from "./components/BlogPost3";
import WhyEwasteCritical from "./components/BlogPost4";
import BatteryRecycling from "./components/BlogPost5";
import CouchRecycling from "./components/BlogPost6";
import DataWipe from "./components/BlogPost7";
import GamingLaptop from "./components/BlogPost8";
import RecycleLaptop from "./components/BlogPost9";
import RecycleComputerMonitor from "./components/BlogPost10";
import BuyAndSell from "./components/BlogPost11";
import OrderConfirm from "./components/OrderConfirmation";
import BlogLayout from "./components/BlogLayout";
import React, {useState} from 'react'





function App() {

  const [currentUser, setCurrentUser] = useState(null)


  return (
    <Router>
    <div className="header-container">
    <Navbar />
    <ScrollToTop />
    </div>
     <main className="main-content">
       <Routes>
         <Route path="/" element={<Home />} />
         <Route path="/about" element={<About userInfo={setCurrentUser}/>} />
         <Route path="/recycle" element={<Recycle />} />
         <Route path="/account" element={<Account CurrentUser={currentUser}/>} />
         <Route path="/contact" element={<Contact />} />
         <Route path="/ordered" element={<OrderConfirm CurrentUser={currentUser} />} />
         
         <Route path="/blog" element={<BlogLayout />}>
            <Route index element={<Blog />} />
            <Route path="broken-tv" element={<BrokenTv />} />
            <Route path="unusual-items" element={<UnusualItems />} />
            <Route path="hoarding-habits" element={<HoardingHabits />} />
            <Route path="ewaste-critical" element={<WhyEwasteCritical />} />
            <Route path="battery-recycling" element={<BatteryRecycling />} />
            <Route path="recycling-centers-near-me" element={<CouchRecycling />} />
            <Route path="data-wipe" element={<DataWipe />} />
            <Route path="sell-your-gaming-laptop" element={<GamingLaptop />} />
            <Route path="where-can-i-recycle-my-laptop-computer" element={<RecycleLaptop />} />
            <Route path="where-can-i-recycle-computer-monitors" element={<RecycleComputerMonitor />} />
            <Route path="buy-and-sell-electronics-near-me" element={<BuyAndSell />} />
          </Route>

       </Routes>
       <Footer />
     </main>
   </Router>
  );
}

export default App;
