import react from 'react'
import styled from 'styled-components';
import unusualAsset from '../assets/unusual.png'
import unusual from '../assets/unusualasset.png'
import roomba from '../assets/roomba.png'
import DeviceLife from './DeviceLifeLanding';

const Container = styled.div`
  padding: 100px 20px 20px; /* Adjust padding to ensure it's not blocked by the navbar */
  font-family: 'Roboto', sans-serif; /* Change font to a more techy style */
  color: #333;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;

   h2 {
    color: #0cc0df;
    text-align: left;
  }

  h1 {
    margin-top: 70px;
    font-size: 2.5em;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 2em;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.2em;
    text-align: justify;
    margin-bottom: 20px;
  }

  ul {
    padding-left: 20px;
  }

  ul li {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    padding: 60px 15px 15px;
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
    p {
      font-size: 1em;
    }
  }
`;

const Banner = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

function unusualItems(){

    return(
        <Container>
           <h1>Some of the MOST UNUSUAL items that count as Electronic Waste </h1>
           <Banner>
            <img src={roomba}/>
            </Banner>
            <p><i>Ryan Rash - August 6th 2024</i></p>
            <p>You’ve probably asked yourself if you can throw away batteries, cell phones, laptops, or TVs into the trash. Well, you CAN, nobody is stopping you, but you shouldn’t. 
                Disposing of E-Waste (Electronic Waste) improperly not only risks environmental contamination and wastes precious materials that have been mined and refined for your use, but opens the possibility of exposing those around you, as well as future generations, to toxic materials. 
                Some of the most unusual electronic waste products you may not have thought of: 
            </p>
            <h2>The Quick List</h2>
            <p>1. <strong>Electronic Toothbrushes:</strong> These devices contain batteries and electronic components that are often unconsidered for recycling of any kind. </p>
            <p>2. <strong>Pet Gadgets:</strong> Devices like automatic pet feeders, GPS trackers, and pet cameras are increasingly popular, but they do have a shelf life and can easily become e-waste when they are outdated or no longer work. </p>
            <p>3. <strong>Smart Clothing:</strong> Wearable technology embedded in clothing, such as fitness tracking shirts or heated jackets, can become e-waste when they break or are no longer functional. </p>
            <p>4. <strong>E-cigarettes and Vapes:</strong> These devices have electronics and batteries, making them a form of e-waste that often isn’t recognized as such by users. </p>
            <p>5. <strong>Electronic Musical Instruments:</strong> Items like electric guitars, digital pianos, and electronic drum kits contain components that can contribute to e-waste if not disposed of properly. </p>
            <p>6. <strong>Smart Appliances:</strong> Internet-connected appliances like refrigerators, ovens, and even kettles can turn into e-waste when they are replaced or no longer function properly.</p>
            <p>7. <strong>Robot Vacuums:</strong> Devices like Roombas and other robotic vacuum cleaners are an example of smart home gadgets that eventually become e-waste. </p>
            <p>8. <strong>Novelty Electronics:</strong> Gadgets like electronic dancing toys, singing fish plaques and other quirky electronic items often become e-waste when they lose their novelty or break down. </p>
            <p>9. <strong>Old Medical Devices:</strong> Home medical electronics, like outdated blood pressure monitors, glucose meters, and CPAP machines, can become e-waste when they are replaced or no longer needed</p>
            <h2>The List goes on...</h2>
            <p>Of course, there are others we probably missed, but we here at TerraForge would venture to guess you have at least three or four of these in your living space right now, used or tucked away. The point is, these items shouldn’t be ignored and don’t need to be hoarded or thrown to waste. </p>
            <p>There are a million ways to reuse and recycle them and keep them from being lost to the dump.Give them a new life with TerraForge! </p>
            <p>Tell us how much or what you have, and we’ll do the hard work of printing you a shipping label to send it away to E-Waste heaven and recrafted with TerraForge.</p>
            <DeviceLife />
        </Container>
    )
}

export default unusualItems;