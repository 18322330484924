import react from 'react'
import styled from 'styled-components';
import critical from "../assets/criticalewaste.png"
import couch from "../assets/couchBlog.png"
import DeviceLife from './DeviceLifeLanding';

const Container = styled.div`
  padding: 100px 20px 20px; /* Adjust padding to ensure it's not blocked by the navbar */
  font-family: 'Roboto', sans-serif; /* Change font to a more techy style */
  color: #333;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;

  h2 {
    color: #0cc0df;
    text-align: left;
  }

  h1 {
    margin-top: 70px;
    font-size: 2.5em;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 2em;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.2em;
    text-align: justify;
    margin-bottom: 20px;
  }

  ul {
    padding-left: 20px;
  }

  ul li {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    padding: 60px 15px 15px;
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
    p {
      font-size: 1em;
    }
  }
`;

const Banner = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

function CouchRecycling(){

    return (
        <Container>
            <h1>From “Recycling Center Near me” to Recycling from your Couch</h1>
            <Banner>
                <img src={couch}/>
            </Banner>
            <p><i>By Ryan Rash - August 14th 2024</i></p>
            <p>No more hauling old electronics to a recycling center, no more guessing if they’ll actually be recycled properly—TerraForge is here to bring electronic recycling into the 21st century. </p>
            <h2>The Problem with Traditional Electronics Recycling</h2>
            <p>If you've ever tried to responsibly dispose of your old electronics (if you’ve ever tried), you know the run around. You have to find a recycling center, figure out when it's open, and then make the trip with your heavy, outdated gadgets you hope count as electronic waste in tow. For most of us, this process is a hassle, leading to the dreaded "tech graveyard" in the corner of our closets or garages.
            Even worse, many people are unaware of where to recycle their e-waste, or they doubt whether their devices will actually be recycled properly once dropped off. This uncertainty and inconvenience lead to a significant amount of electronics ending up in landfills, contributing to environmental pollution and the loss of valuable materials.
            </p>
            <h2>TerraForge: The On-Demand Solution for E-Waste</h2>
            <p>
            Just as Uber and Lyft took the frustration out of getting a ride, TerraForge is set to make recycling your electronics as easy as ordering a pizza. The concept is simple: you schedule a pickup online, pack up your old gadgets, and TerraForge does the rest. They print you a label you slap on a box and take your e-waste off your hands, sometimes netting you a profit and always rewarding you with more storage space.
            TerraForge turns a tedious chore into a simple, efficient, and environmentally friendly process.
            No more lugging heavy, outdated tech across town. No more wondering whether your old phone will end up being properly recycled or if the data is safe.
            </p>
            <h2>Convenience is Key</h2>
            <p>
            The beauty of TerraForge lies in its simplicity and convenience. We live in a world where time is a premium commodity. TerraForge removes the barriers to responsible electronic recycling. It's the kind of service that makes you wonder, "Why hasn't someone done this before?"
            The process is streamlined and user-friendly. You print a return label via their app or website, package your electronics in a box (no need for fancy packing, just secure enough for transit), and leave it on your doorstep. TerraForge takes it from there, ensuring that your devices are recycled or repurposed in an environmentally responsible manner.
            </p>
            <h2>A Commitment to the Environment and Confidentiality</h2>
            <p>
            Beyond convenience, TerraForge is driven by a mission to reduce electronic waste and its harmful impact on the environment. Electronics are full of valuable materials like gold, silver, and rare earth metals that can be reused if properly recycled. By offering an easy way to dispose of old electronics, TerraForge helps reduce the amount of e-waste that ends up in landfills, promoting a more sustainable future.
            TerraForge is set to disrupt the electronic recycling industry. It's not just about making recycling easier—it's about making it the norm.
            </p>
            <h2>The Future of E-Waste Recycling</h2>
            <p>
            TerraForge is more than just a convenience—it's a game-changer. As more people adopt the service, the days of e-waste cluttering our homes or ending up in landfills could become a thing of the past. Just as we now expect to be able to summon a ride with the tap of a button, we could soon expect the same ease when it comes to recycling our electronics.
            The future of e-waste recycling is here. TerraForge is making responsible recycling as easy as ordering a ride—and that’s a revolution we can all get behind.
            </p>
            <DeviceLife />
        </Container>
    )
}

export default CouchRecycling;