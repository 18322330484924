import React from 'react';
import styled from 'styled-components';
import recycleLaptop from '../assets/recyclelaptop.png'

const BlogContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 100px;
  line-height: 1.6;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  font-size: 2.5em;
  margin-bottom: 0.5em;
`;

const Subtitle = styled.h2`
  font-size: 2em;
  margin-bottom: 1em;
  color: #333;
`;

const SectionTitle = styled.h3`
  font-size: 1.5em;
  margin-top: 1.5em;
`;

const Paragraph = styled.p`
  margin-bottom: 1em;
`;

const List = styled.ul`
  margin-left: 20px;
  margin-bottom: 1.5em;
`;

const ListItem = styled.li`
  margin-bottom: 0.5em;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  margin: 1.5em 0;
`;

const RecycleLaptop = () => {
  return (
    <BlogContainer>
      <Title>Where Can I Recycle Laptop Computers?</Title>
      <Subtitle>Eco-Friendly Ways to Dispose of Your Old Laptop</Subtitle>

      <Paragraph>
        If you have an old laptop that you no longer use, recycling it is one of the best ways to dispose of it responsibly. But where can you recycle laptop computers? In this guide, we’ll explore the best options for recycling your laptop, ensuring it doesn’t end up in a landfill.
      </Paragraph>

      <Image src={recycleLaptop} alt="Where to recycle laptop computers" />

      <SectionTitle>1. Recycle it with Terraforge.</SectionTitle>
      <List>
          <ListItem>
          <strong><a href="https://www.tforge.co/recycle" target="_blank" rel="noopener noreferrer">Terraforge</a></strong> Terraforge offers the easiest way to recycle your old laptops within minutes. Simply select how much you need to send in, and we'll recycle it for free. We provide a 100% data privacy guarantee to ensure your data is completely wiped as soon as it arrives. The best part, if you're laptop can be refurbished, we'll pay you for what it's worth! 
          </ListItem>
      </List>

      <SectionTitle>2. Manufacturer Take-Back Programs</SectionTitle>
      <Paragraph>
        Many laptop manufacturers offer take-back programs that allow you to send your old laptop back to them for recycling. Companies like <strong><a href="https://www.apple.com/shop/trade-in" target="_blank" rel="noopener noreferrer">Apple</a></strong> and <strong><a href="https://www.hp.com/us-en/shop/cv/recycle.html" target="_blank" rel="noopener noreferrer">HP</a></strong> have robust recycling programs that make it easy to dispose of your laptop in an environmentally friendly way.
      </Paragraph>
      <List>
        <ListItem>
          <strong>Apple:</strong> Apple’s trade-in program offers you the option to recycle your old MacBook and receive store credit if it's still in good condition.
        </ListItem>
        <ListItem>
          <strong>HP:</strong> HP provides free recycling for all HP-branded products, and they make it easy with prepaid shipping labels.
        </ListItem>
      </List>

      <SectionTitle>3. Retailer Recycling Programs</SectionTitle>
      <Paragraph>
        Some electronics retailers also offer recycling services for laptops and other devices. Stores like <strong><a href="https://www.bestbuy.com/site/services/recycling/pcmcat149900050025.c?id=pcmcat149900050025" target="_blank" rel="noopener noreferrer">Best Buy</a></strong> and <strong><a href="https://www.staples.com/sbd/cre/marketing/sustainability-center/recycling-services/electronics/" target="_blank" rel="noopener noreferrer">Staples</a></strong> have dedicated recycling programs where you can drop off your old laptop for free.
      </Paragraph>
      <List>
        <ListItem>
          <strong>Best Buy:</strong> Best Buy offers free recycling for laptops, regardless of where you bought them. Just bring your laptop to the store, and they’ll take care of the rest.
        </ListItem>
        <ListItem>
          <strong>Staples:</strong> Staples accepts laptops and other electronics for recycling, with no purchase necessary. It’s a convenient option if you live near a Staples store.
        </ListItem>
      </List>

      <SectionTitle>4. Local E-Waste Recycling Centers</SectionTitle>
      <Paragraph>
        Many communities have local e-waste recycling centers where you can drop off your laptop for recycling. These centers specialize in safely disposing of electronics and often accept a wide range of devices, including laptops.
      </Paragraph>
      <Paragraph>
        To find a recycling center near you, check out resources like <strong><a href="https://www.earth911.com" target="_blank" rel="noopener noreferrer">Earth911</a></strong> or contact your local waste management authority.
      </Paragraph>

      <SectionTitle>5. Charitable Organizations</SectionTitle>
      <Paragraph>
        If your laptop is still in working condition, consider donating it to a charitable organization. Groups like <strong><a href="https://www.goodwill.org" target="_blank" rel="noopener noreferrer">Goodwill</a></strong> and <strong><a href="https://www.salvationarmyusa.org" target="_blank" rel="noopener noreferrer">The Salvation Army</a></strong> accept electronics donations, which they may refurbish or recycle. This option helps reduce e-waste and supports a good cause.
      </Paragraph>

      <SectionTitle>Tips for Preparing Your Laptop for Recycling</SectionTitle>
      <Paragraph>
        Before you recycle your laptop, there are a few important steps you should take to protect your personal information and prepare the device for recycling:
      </Paragraph>
      <List>
        <ListItem>Back up any important data to an external drive or cloud storage.</ListItem>
        <ListItem>Factory reset your laptop to erase all personal information.</ListItem>
        <ListItem>Remove any external accessories, like the battery or memory card, if they can be recycled separately.</ListItem>
        <ListItem>Consider removing the hard drive if you're concerned about data security.</ListItem>
      </List>

      <SectionTitle>Conclusion</SectionTitle>
      <Paragraph>
        Recycling your old laptop is a simple and responsible way to dispose of your electronics. Whether you choose a manufacturer take-back program, retailer recycling, a local e-waste center, or a charitable organization, there are plenty of options to ensure your laptop is recycled properly. By taking the time to recycle, you’re helping to protect the environment and reduce electronic waste.
      </Paragraph>

      <Paragraph>
        For more tips on recycling and upcycling your electronics, check out our other articles. Together, we can make a difference in reducing e-waste and promoting sustainable practices.
      </Paragraph>
    </BlogContainer>
  );
};

export default RecycleLaptop;
