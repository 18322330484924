import React from 'react';
import styled from 'styled-components';
import roomba from '../assets/roomba.png'
import brokentv from '../assets/brokentv.png'
import unusual from '../assets/unusualasset.png'
import critical from '../assets/criticalewaste.png'
import batteries from '../assets/batteries.png'
import couch from "../assets/couchBlog.png"
import reset from "../assets/resetdata.png"
import { useNavigate } from 'react-router-dom';

const BlogContainer = styled.div`
  display: flex;
  margin-top: 40px;
  padding: 60px;

`;

const MainContent = styled.div`
  flex: 3;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const FilterColumn = styled.div`
  flex: 1;
  margin-left: 20px;
`;

const Card = styled.div`
  flex: 1 1 calc(33.333% - 20px);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
`;

const Image = styled.img`
    width: 100%;
`;

const Blog = () => {
    const navigate = useNavigate()

    const handleToBrokenTv = () => {
        // Navigate back to the home page
        navigate('/blog/broken-tv');
      };

      const handleToUnusualItems= () => {
        // Navigate back to the home page
        navigate('/blog/unusual-items');
      };

      const handleToHoardingHabits= () => {
        // Navigate back to the home page
        navigate('/blog/hoarding-habits');
      };

      const handleToCriticalEwaste= () => {
        // Navigate back to the home page
        navigate('/blog/ewaste-critical');
      };

      const handleToBatteryRecycling= () => {
        // Navigate back to the home page
        navigate('/blog/battery-recycling');
      };

      const handleToCouchRecycling= () => {
        // Navigate back to the home page
        navigate('/blog/recycling-centers-near-me');
      };

      const handleToDataWipe= () => {
        // Navigate back to the home page
        navigate('/blog/data-wipe');
      };


  return (
    <BlogContainer>
      <MainContent>
      <Card onClick={handleToHoardingHabits}>
            <Image src={unusual} />
            <h1>The Great Electronic Graveyard: A Quick Guide to Our Hoarding Habits</h1>
            <h3>August 6th 2024</h3>
            <p>By Ryan Rash</p>
            <p><i>5 minute read</i></p>
        </Card>
        <Card onClick={handleToCouchRecycling}>
            <Image src={couch} />
            <h1>From “Recycling Center Near me” to Recycling From your Couch</h1>
            <h3>August 14th 2024</h3>
            <p>By Ryan Rash</p>
            <p><i>8 minute read</i></p>
        </Card>
        <Card onClick={handleToDataWipe}>
            <Image src={reset} />
            <h1>A Factory Reset to Set You Up for Success: How to safely remove your data before recycling</h1>
            <h3>August 14th 2024</h3>
            <p>By Ryan Rash</p>
            <p><i>9 minute read</i></p>
        </Card>
      <Card onClick={handleToBrokenTv}>
           <Image src={brokentv} />
            <h1>How to Get Rid of a Broken or Old TV: A Guide Series on how to Reduce E-Waste</h1>
            <h3>August 6th 2024</h3>
            <p>By Ryan Rash</p>
            <p><i>5 minute read</i></p>
            
        </Card>
        <Card onClick={handleToUnusualItems}>
            <Image src={roomba} />
            <h1>Some of the MOST UNUSUAL items that count as Electronic Waste</h1>
            <h3>August 6th 2024</h3>
            <p>By Ryan Rash</p>
            <p><i>5 minute read</i></p>
        </Card>
        <Card onClick={handleToCriticalEwaste}>
            <Image src={critical} />
            <h1>Why Electronic Recycling is Crucial for the Environment</h1>
            <h3>August 7th 2024</h3>
            <p>By Gianni Cherry</p>
            <p><i>8 minute read</i></p>
        </Card>
        <Card onClick={handleToBatteryRecycling}>
            <Image src={batteries} />
            <h1>How to Safely Dispose of Batteries: A Comprehensive Guide</h1>
            <h3>August 7th 2024</h3>
            <p>By Gianni Cherry</p>
            <p><i>8 minute read</i></p>
        </Card>
      </MainContent>
    </BlogContainer>
  );
};

export default Blog;
